import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link, withRouter } from 'react-router-dom';

import './style.scss';

const Routes = {
  aboutMe: '/about-me',
  services: '/services',
  faq: '/faq',
  feesAndInsurance: '/fees-and-insurance',
  contact: '/contact',
};

const isMobile = () => window.innerWidth < 1150;

class Navigation extends Component {
  constructor(props) {
    super(props);

    this.updateDimensions = this.updateDimensions.bind(this);
    this.toggleMobileDropdown = this.toggleMobileDropdown.bind(this);
    this.state = {
      isMobile: isMobile(),
      isMobileOpen: false,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  toggleMobileDropdown() {
    this.setState({
      isMobileOpen: !this.state.isMobileOpen,
    });
  }

  updateDimensions() {
    const isMobileSize = isMobile();
    if (isMobileSize && !this.state.isMobile) {
      this.setState({ isMobile: true });
    } else if (!isMobileSize && this.state.isMobile) {
      this.setState({ isMobile: false });
    }
  }

  renderLinks() {
    return [
      <Link
        key={Routes.aboutMe}
        to={Routes.aboutMe}
        className={classNames('Navigation-Link', {
          'Navigation-Link--selected':
            this.props.location.pathname === Routes.aboutMe,
          'Navigation-Link--mobile': this.state.isMobile,
        })}
      >
        About
      </Link>,
      <Link
        key={Routes.services}
        to={Routes.services}
        className={classNames('Navigation-Link', {
          'Navigation-Link--selected':
            this.props.location.pathname === Routes.services,
          'Navigation-Link--mobile': this.state.isMobile,
        })}
      >
        Services
      </Link>,
      <Link
        key={Routes.faq}
        to={Routes.faq}
        className={classNames('Navigation-Link', {
          'Navigation-Link--selected':
            this.props.location.pathname === Routes.faq,
          'Navigation-Link--mobile': this.state.isMobile,
        })}
      >
        FAQ
      </Link>,
      <Link
        key={Routes.feesAndInsurance}
        to={Routes.feesAndInsurance}
        className={classNames('Navigation-Link', {
          'Navigation-Link--selected':
            this.props.location.pathname === Routes.feesAndInsurance,
          'Navigation-Link--mobile': this.state.isMobile,
        })}
      >
        Fees & Insurance
      </Link>,
      <Link
        key={Routes.contact}
        to={Routes.contact}
        className={classNames('Navigation-Link', {
          'Navigation-Link--selected':
            this.props.location.pathname === Routes.contact,
          'Navigation-Link--mobile': this.state.isMobile,
        })}
      >
        Contact
      </Link>,
    ];
  }

  renderMobileNav() {
    return (
      <button
        type="button"
        className={classNames('Navigation-Ham hamburger hamburger--vortex', {
          'is-active': this.state.isMobileOpen,
        })}
        onClick={this.toggleMobileDropdown}
        aria-label="open menu"
      >
        <span className="hamburger-box">
          <span className="hamburger-inner" />
        </span>
        <div className="Navigation-Content">{this.renderLinks()}</div>
      </button>
    );
  }

  renderDesktopNav() {
    return <div className="Navigation-PageContainer">{this.renderLinks()}</div>;
  }

  render() {
    return (
      <div className="Navigation">
        <div className="Navigation-Container">
          <Link to="/" className="Navigation-LogoContainer">
            <div className="Navigation-Logo">Renée Pigsley</div>
          </Link>
          {this.state.isMobile
            ? this.renderMobileNav()
            : this.renderDesktopNav()}
        </div>
      </div>
    );
  }
}

Navigation.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default withRouter(Navigation);
