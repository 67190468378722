import React from 'react';

import Button from 'primitives/button';

import './style.scss';

export default function Home() {
  return (
    <div className="Home">
      <div className="Home-Filter">
        <div className="Home-ContentContainer">
          <h1>Renée Pigsley Counseling</h1>
          <h2 className="Home-SubHeader">
            &#034;If we can share our story with someone who responds with
            empathy and understanding, shame can&#039;t survive.&#034;
          </h2>
          <p className="Home-QuoteAuthor">
            -{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.goodreads.com/author/show/162578.Bren_Brown"
            >
              Brené Brown
            </a>
          </p>
          <div className="Home-FlexContainer">
            <Button to="/about-me">Learn More</Button>
          </div>
        </div>
      </div>
    </div>
  );
}
