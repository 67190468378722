import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import AppContainer from 'components/app-container';
import Navigation from 'components/navigation';
import Home from 'components/home';
import AboutMe from 'components/about-me';
import Services from 'components/services';
import FAQ from 'components/faq';
import FeesAndInsurance from 'components/fees-and-insurance';
import Contact from 'components/contact';

import 'hamburgers/dist/hamburgers.css';
import 'styles/global.scss';

ReactDOM.render(
  <Router>
    <AppContainer>
      <Navigation />
      <Route exact path="/" component={Home} />
      <Route path="/about-me" component={AboutMe} />
      <Route path="/services" component={Services} />
      <Route path="/faq" component={FAQ} />
      <Route path="/fees-and-insurance" component={FeesAndInsurance} />
      <Route path="/contact" component={Contact} />
    </AppContainer>
  </Router>,
  document.getElementById('root'),
);
