import React from 'react';

import PageContainer from 'primitives/page-container';
import FlexContainer from 'primitives/flex-container';

import './style.scss';

export default function Services() {
  return (
    <PageContainer className="Services">
      <div className="Services-Container">
        <h1>Services</h1>
        <h3>Individual Counseling</h3>
        <p>
          Individual counseling sessions are approximately 50 minutes in length.
          I draw from both a psychodynamic and cognitive behavioral therapy
          (CBT) approach. I find that the psychodynamic aim of understanding our
          childhood experiences and unconscious processes provides helpful
          motivation and framework for challenging maladaptive and
          self-sabotaging thoughts. We may work through concerns by processing
          their beginnings, contributing factors, and areas in which you feel
          comfortable making small changes. I have tools that may help in
          working through these concerns, and you have the ultimate say in what
          you feel will or won’t work for you.
        </p>
        <p>Below are some common problems and concerns I frequently treat:</p>
        <FlexContainer>
          <ul className="Services-List">
            <li>
              Eating Disorders (anorexia, bulimia, binge eating disorder,
              ED-NOS)
            </li>
            <li>Spirituality &amp; issues of religious deconversion</li>
            <li>LGBTQ Issues</li>
            <li>Life Transitions</li>
            <li>Anxiety</li>
          </ul>
          <ul className="Services-List">
            <li>Depression</li>
            <li>Identity development</li>
            <li>Relationship problems</li>
            <li>Trauma</li>
            <li>Low self-esteem</li>
          </ul>
        </FlexContainer>
        <h3>Couples Counseling</h3>
        <p>
          For couples struggling to stay deeply connected, or for those who have
          found themselves at a crisis point, my goal is to help facilitate
          deeper love and respect for each other. Often, this includes working
          to develop an understanding of the needs of both self, partner(s),
          and the relationship as a whole, and altering behaviors that may
          hinder that. I have completed Level 1 Gottman training, and employ
          many of the Gottman Institute’s evidence based assessments and
          techniques to aid in repairing relationships. 
        </p>
        <h3>Group Therapy: Meaningful Relationships</h3>
        <p>
          Creating and maintaining relationships doesn’t come naturally for
          everyone. Learning how to navigate new relationships, connect deeply
          with people, and manage relational conflict are core focus areas of
          this group. I facilitate with guiding questions, and group members are
          able to offer insight and support to one another.
        </p>
        <p>
          Meaningful Relationships meets once per week for eight weeks, at
          several points throughout the year. Typically, this group is held on
          Thursdays from 7-8:30pm. Please contact me for info on upcoming group
          start dates.
        </p>
        <h3>Telehealth</h3>
        <p>
        I offer telehealth (secure, HIPAA compliant video-based therapy) to
        clients that do not live in the Fort Collins area, as well as for
        clients whose circumstances may prevent regular office visits (i.e.
        weather, transportation, health, etc.).
        </p>
      </div>
    </PageContainer>
  );
}
