import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './style.scss';

export default function Input(props) {
  if (props.type === 'textarea') {
    return (
      <textarea {...props} className={classNames(props.className, 'Input')} />
    );
  }
  return <input {...props} className={classNames(props.className, 'Input')} />;
}

Input.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
};

Input.defaultProps = {
  type: null,
  className: null,
};
