import React from 'react';

import PageContainer from 'primitives/page-container';
import Link from 'primitives/link';
import Input from 'primitives/input';
import Button from 'primitives/button';

import './style.scss';

export default function Contact() {
  return (
    <PageContainer className="Contact">
      <h1>Contact</h1>
      <div className="Contact-Container">
        <div className="Contact-Address">
          <h3>Renée Pigsley Counseling</h3>
          <div className="Contact-AddressContainer">
            <p className="Contact-ETOO">
              Fort Collins, CO, 80525
              <br />
              <Link href="tel:14028198694">(402) 819-8694</Link>
              <br />
              <Link href="mailto:renee@reneepigsley.com">
                renee@reneepigsley.com
              </Link>
            </p>
          </div>
          <iframe
            title="Renee Pigsley Counseling"
            className="Contact-Map"
            frameBorder="0"
            scrolling="no"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d97032.92790911277!2d-105.09035388076657!3d40.53542485471368!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87694ca56413fc87%3A0xad9f42081fac759f!2sFort%20Collins%2C%20CO%2080525!5e0!3m2!1sen!2sus!4v1617030504281!5m2!1sen!2sus"
            allowFullScreen
          />
        </div>
        <div className="Contact-FormContainer">
          <h3>Request an Appointment</h3>
          <form
            className="Contact-Form"
            action="https://formspree.io/f/renee@reneepigsley.com"
            method="POST"
          >
            <label htmlFor="name">Name</label>
            <Input type="text" name="name" />
            <label htmlFor="phone">Phone Number</label>
            <Input type="tel" name="phone" />
            <label htmlFor="_replyto">Email</label>
            <Input type="email" name="_replyto" />
            <label htmlFor="comments">Comments</label>
            <Input
              type="textarea"
              name="comments"
              className="Contact-Textarea"
            />
            <Button type="submit" />
          </form>
        </div>
      </div>
    </PageContainer>
  );
}
