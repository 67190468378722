import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

export default function AppContainer(props) {
  return <div className="AppContainer">{props.children}</div>;
}

AppContainer.propTypes = {
  children: PropTypes.node.isRequired,
};
