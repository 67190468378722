import React from 'react';

import PageContainer from 'primitives/page-container';
import Link from 'primitives/link';

import './style.scss';

export default function FeesAndInsurance() {
  return (
    <PageContainer className="FeesAndInsurance">
      <div className="FeesAndInsurance-Container">
        {/* <h1>Fees & Insurance</h1>
        <p>Below are my standard fees for services:</p>
        <ul className="FeesAndInsurance-Fees">
          <li>
            Initial phone consultation (15-20 mins): <b>Free</b>
          </li>
          <li>
            Individual counseling session (50 mins): <b>$75</b>
          </li>
          <li>
            Couples/family counseling session (50 mins): <b>$100</b>
          </li>
          <li>
            Group counseling session (90 mins): <b>$15</b>
          </li>
        </ul> */}
        <p>
          Forms of payment accepted: Cash, Check, Health Savings Account, Visa,
          MasterCard, American Express, Discover.
        </p>
        <p>
          For more information about payment, please contact me at{' '}
          <Link href="tel:14028198694">(402) 819-8694</Link>
        </p>
        <p>Insurance Accepted:</p>
        <ul className="FeesAndInsurance-Fees">
          <li>Blue Cross Blue Shield</li>
        </ul>
      </div>
    </PageContainer>
  );
}
