import React from 'react';

import FlexContainer from 'primitives/flex-container';
import PageContainer from 'primitives/page-container';

import './style.scss';

export default function AboutMe() {
  return (
    <PageContainer className="AboutMe">
      <FlexContainer direction="column">
        <FlexContainer className="AboutMe">
          <img
            alt="Renée Pigsley"
            src="/images/renee.jpg"
            className="AboutMe-WebPhoto"
          />
          <div className="AboutMe-Content">
            <h1>About</h1>
            <div className="AboutMe-MobilePhotoContainer">
              <img
                alt="Renée Pigsley"
                src="/images/renee.jpg"
                className="AboutMe-MobilePhoto"
              />
            </div>
            <p>
              I am a licensed professional counselor (LPC) in Fort Collins, CO,
              where I see clients in person. I also hold licensure in Nebraska
              (Licensed Mental Health Practitioner/LMHP), and provide counseling
              sessions via telehealth to NE clients. I earned a Bachelor of Arts
              in Psychology from Nebraska Wesleyan University in 2015, and a
              Master of Science in Clinical Mental Health Counseling from the
              University of Nebraska-Omaha in 2017. Education and training have
              allowed me to practice, but my own experiences in life have fueled
              passion for walking with clients through change.
            </p>
            <p>
              So, who do I tend to work with? Anyone curious about taking a look
              at what created pain and suffering, and what is keeping it in
              motion. That said, I do have a few areas of focus that make up the
              bulk of my practice.
            </p>
            <p>
              The first is with those healing from religious deconstruction and
              religious trauma. I welcome the opportunity to work with people
              leaving religion, fundamentalism, high control groups/cults, and
              other belief systems they no longer identify with. It is a journey
              of both courage and struggle for many, yet few resources exist to
              support those experiencing deconversion or religious trauma. I aim
              to help clients navigate life after deconstruction- often a time
              of profound change.
            </p>
            <p>
              Additionally, I have significant interest and experience working
              with clients suffering from eating disorders. In partial
              hospitalization and outpatient settings, walking with clients
              through recovery has been a highlight of my career thus far. I
              understand that every person’s treatment needs are different and
              will work at a pace that feels comfortable and growth-oriented.
              While focusing on challenging ED behaviors is an integral part of
              treatment, it is not more important to me than working to heal the
              underlying beliefs, traumas, and experiences that have laid the
              groundwork for an eating disorder to develop.
            </p>
            <p>
              A general belief that guides my work is the understanding that
              each client knows themselves best, and that my role is to help
              facilitate self-exploration in a warm, yet direct way. This often
              includes some focus on experiences and relationships that have had
              significant impact in life, as well as the ‘big picture,’ or how
              one views the world and their role within it.
            </p>
            <p>
              In my free time, I enjoy being outside as much as I can. Usually,
              that takes the form of gardening, hiking, climbing, or spending
              time with my partner and (velcro) dogs, Freya and Sigyn.
            </p>
            <span className="AboutMe-QuoteContainer">
              <p className="AboutMe-Quote">
                &#034;The privilege of a lifetime is to become who you truly
                are.&#034;
              </p>
              <p className="AboutMe-Author">
                -{' '}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.goodreads.com/author/show/38285.C_G_Jung"
                >
                  C.G. Jung
                </a>
              </p>
            </span>
          </div>
        </FlexContainer>
        <FlexContainer justify="center" wrap>
          <img
            alt="Renée Pigsley's Waiting Room"
            src="/images/waitingroom.jpg"
            className="AboutMe-Office"
          />
          <img
            alt="Renée Pigsley's Office 1"
            src="/images/office1.jpg"
            className="AboutMe-Office"
          />
          <img
            alt="Renée Pigsley's Office 2"
            src="/images/office2.jpg"
            className="AboutMe-Office"
          />
        </FlexContainer>
      </FlexContainer>
    </PageContainer>
  );
}
