import React, { Component } from 'react';

import PageContainer from 'primitives/page-container';

import './style.scss';

export default class FAQ extends Component {
  render() {
    return (
      <PageContainer className="FAQ">
        <div className="FAQ-Container">
          <h1>FAQ</h1>
          <h3>
            I’ve never been to therapy before. What will the first session be
            like?
          </h3>
          <p>
            The first session will be a lot of me getting to know you. You are
            free to tell me what you feel is most important to your time in
            therapy, and I will follow up with questions that may help paint a
            fuller picture of who you are and what you’d like to work toward.
          </p>
          <h3>Do you treat children/adolescents? How does that work?</h3>
          <p>
            At this time I am not accepting new clients under the age of 19.
          </p>
          <h3>What happens if I need to cancel or reschedule a session?</h3>
          <p>
            My cancellation policy is such that I require 24 hours notice if you
            need to cancel or reschedule. However, I understand that life can
            get hectic. One cancellation with less than 24 hours notice is
            waived, and after that a $50 fee will be charged to you for each
            cancellation/no-show.
          </p>
        </div>
      </PageContainer>
    );
  }
}
