import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './style.scss';

export default function Link({ className, children, ...props }) {
  return (
    <a {...props} className={classNames(className, 'Link')}>
      {children}
    </a>
  );
}

Link.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Link.defaultProps = {
  className: null,
};
