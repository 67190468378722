import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './style.scss';

export default function PageContainer(props) {
  return (
    <div className={classNames('PageContainer', props.className)}>
      {props.children}
    </div>
  );
}

PageContainer.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

PageContainer.defaultProps = {
  children: null,
  className: null,
};
