import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import './style.scss';

export default function Button(props) {
  if (props.to) {
    return (
      <Link {...props} className={classNames(props.className, 'Button')}>
        {props.children}
      </Link>
    );
  } else if (props.href) {
    return (
      <a {...props} className={classNames(props.className, 'Button')}>
        {props.children}
      </a>
    );
  } else if (props.type === 'submit') {
    return (
      <input {...props} className={classNames(props.className, 'Button')} />
    );
  }
  return (
    <button {...props} className={classNames(props.className, 'Button')}>
      {props.children}
    </button>
  );
}

Button.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  to: PropTypes.string,
  href: PropTypes.string,
  type: PropTypes.string,
};

Button.defaultProps = {
  children: null,
  className: null,
  to: null,
  href: null,
  type: null,
};
